@use '@/styles/utils/mixins.scss' as *;

.motusStripWrapper {
  display: flex;
  align-items: center;
  background-color: $kiaMidnightBlack;

  .motusStripLogo {
    width: 155px;
    height: 56px;
    position: relative;
    width: fit-content;
  }

  .leftWhiteStip {
    position: relative;
    height: 56px;
    background: $kiaPolarWhite;
    left: 0;
    width: 20px;
    @include tab() {
      width: 0;
    }
  }
}

.copyRightText {
  margin-left: auto;
  font-size: 14px;
  color: $kiaPolarWhite;
  padding-right: 3rem;
  @include tab() {
    padding-right: 24px;
  }
}
