.resultsPagination {
  display: flex;
  justify-content: center;

  li {
    margin: 8px !important;
  }

  a.link {
    display: block !important;
    width: 100%;
    height: 100%;
  }
}

